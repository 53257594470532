import React, { useId, useState } from 'react';
import {
    Dialog,
    makeStyles,
    Label,
    Input,
    InputProps,
    DialogTrigger,
    Button,
    DialogSurface,
    DialogBody,
    InputOnChangeData,
    DialogActions,
    DialogContent,
    DialogTitle,
    MessageBar,
    MessageBarBody,
    MessageBarIntent,
    Field,
    ToolbarButton
} from "@fluentui/react-components";
import { userFieldsValidations, websiteuser } from '../dataSource/websiteuser';
import { websiteDataProvider } from '../dataSource/websiteDataProvider';
import { SignOut24Regular, Person24Regular, PersonAdd24Regular } from '@fluentui/react-icons';
import { useAppContext } from '../dataSource/appContextProvider';

const useStyles = makeStyles({
    root: {
        // Stack the label above the field
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        gap: "2px",
        maxWidth: "450px",

    },
    input: {
        // Prevent the example from taking the full width of the page (optional)

    },
    field: {
        marginTop: '5px'
    },
    dialog: { width: '450px' }
});


const LoginForm = () => {
    const styles = useStyles();
    const [showDialog, setShowDialog] = useState(false);
    const dataProvider: websiteDataProvider = websiteDataProvider.getInstance();
    const defaultUser: websiteuser = {
        username: '',
        email: '',
        phonenumber: '',
        password: '',
        studentname: ''
    }
    const defaultValidateFields: userFieldsValidations = {
        userNameFieldMessage: '',
        userNameFieldStatus: '',
        passwordFieldMessage: '',
        passwordFieldStatus: '',
        studentNameFieldMessage: '',
        studentNameFieldStatus: '',
        emailFieldMessage: '',
        emailFieldStatus: ''
    }
    const [createButtonDisabled, setCreateButtonDisabled] = useState(false);
    const [formData, setFormData] = useState(defaultUser);
    const [message, setMessage] = useState('');
    const [intent, setIntent] = useState('');
    const [validationFields, setvalidationFields] = useState(defaultValidateFields);
    const { login } = useAppContext();

    const handleChange = (name: string, data: InputOnChangeData) => {
        const { value } = data;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async () => {
        if (!validateFields()) {
            return;
        }
        const currentUser: websiteuser | undefined = await dataProvider.getUserByNameAndPassword(formData.username, formData.password);
        if (currentUser) {
            login(currentUser);
        } else {
            setMessagebar('Account does not exist, or password is incorrect.', 'error');
        }
    };
    const validateFields = (): boolean => {
        const newValidation = { ...defaultValidateFields };
        if (!formData.username) {
            newValidation.userNameFieldMessage = 'User name cannot be empty.';
            newValidation.userNameFieldStatus = 'error';
            setvalidationFields(newValidation);
            return false;
        }
        if (!formData.password) {
            newValidation.passwordFieldMessage = 'Password cannot be empty.';
            newValidation.passwordFieldStatus = 'error';
            setvalidationFields(newValidation);
            return false;
        }
        return true;
    }

    const setMessagebar = (message: string, intent: string) => {
        setMessage(message);
        setIntent(intent);
    }

    return (<Dialog modalType='modal' open={showDialog}>
        <DialogTrigger disableButtonEnhancement>
            <ToolbarButton onClick={() => setShowDialog(true)}
                appearance='primary'
                icon={<Person24Regular />}>
                登录
            </ToolbarButton>
        </DialogTrigger>
        <DialogSurface className={styles.dialog}>
            <DialogBody>
                <DialogTitle>请登录</DialogTitle>
                <DialogContent>
                    <div className={styles.root}>
                        {message.length > 0 && < MessageBar intent={intent as MessageBarIntent}>
                            <MessageBarBody>
                                {message}
                            </MessageBarBody>
                        </MessageBar>}
                        <Field
                            className={styles.field}
                            label="用户名"
                            required
                            validationState={validationFields.userNameFieldStatus as "none" | "success" | "error" | "warning" | undefined}
                            validationMessage={validationFields.userNameFieldMessage}>
                            <Input className={styles.input} type='text' value={formData.username}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => { handleChange('username', data); }} required id={"username"} />
                        </Field>
                        <Field
                            className={styles.field}
                            label="密码"
                            required
                            validationState={validationFields.passwordFieldStatus as "none" | "success" | "error" | "warning" | undefined}
                            validationMessage={validationFields.passwordFieldMessage}>
                            <Input className={styles.input} type='password' value={formData.password} onChange={(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => { handleChange('password', data); }} required id={"password"} />
                        </Field>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button appearance='primary' onClick={handleSubmit} disabled={createButtonDisabled}>确认</Button>
                    <Button appearance="secondary" onClick={() => setShowDialog(false)}>取消</Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>);
};

export default LoginForm;