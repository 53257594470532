import { useState } from 'react';
import {
    makeStyles,
    Toaster
} from "@fluentui/react-components";

import { List, ListItem } from "@fluentui/react-list-preview";
import { websiteDataProvider } from '../dataSource/websiteDataProvider';
import nothingImg from '../assets/nothing.jpg';
import { websiteuser } from '../dataSource/websiteuser';
import UserCard from './userCard';

const useStyles = makeStyles({
    listItem: {
        padding: "5px"
    },
});

const AllUsersList = () => {
    const styles = useStyles();
    const dataProvider: websiteDataProvider = websiteDataProvider.getInstance();
    const [allUsers, setAllUsers] = useState<websiteuser[]>([]);

    dataProvider.getAllUsers().then((users: websiteuser[]) => {
        setAllUsers(users);
    });

    return (
        allUsers == undefined || allUsers.length === 0 ?
            <div style={{ height: 300, width: 300 }}>
                <img height={300} width={300} src={nothingImg} alt="Description" />
            </div> :
            <List>
                {allUsers.map((user: websiteuser) => {
                    return (
                        <ListItem className={styles.listItem}>
                            <Toaster toasterId={'toaster1'} />
                            <UserCard user={user} />
                        </ListItem>
                    );
                })}
            </List >);
};

export default AllUsersList;