import React, { createContext, useContext, useState, ReactNode } from 'react';
import { websiteuser } from './websiteuser';

interface AppContextType {
  isAuthenticated: boolean;
  currentUser: websiteuser | null;
  login: (user: websiteuser) => void;
  logout: () => void;
}

export const AppContext = createContext<AppContextType | undefined>(undefined);

const AppContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState<websiteuser | null>(null);

  const login = (user: websiteuser) => {
    setIsAuthenticated(true);
    setCurrentUser(user);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setCurrentUser(null);
  };

  return (
    <AppContext.Provider value={{ isAuthenticated, currentUser, login, logout }}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AppContextProvider, useAppContext };
