import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  TabList,
  Tab,
  Divider,
  SelectTabEvent,
  SelectTabData,
  TabValue,
} from "@fluentui/react-components";
import { Topbar } from "./topbar";
import {
  AppContextProvider,
  useAppContext,
} from "./dataSource/appContextProvider";
import AvailableCourses from "./courseManagement/AvailableCourses";
import MyCourses from "./courseManagement/MyCourses";
import AllUsersList from "./userManagement/allUsersList";
import PastCourses from "./courseManagement/PastCourses";

const MainTabs = () => {
  const [tabSelectedValue, setTabSelectedValue] =
    React.useState<TabValue>("available");
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setTabSelectedValue(data.value);
  };
  const { isAuthenticated, currentUser, login } = useAppContext();

  return (
    <div>
      <TabList
        onTabSelect={onTabSelect}
        defaultValue={"available"}
        selectedValue={tabSelectedValue}
      >
        <Tab value="available">课程安排</Tab>
        {isAuthenticated && <Tab value="my">我的预约</Tab>}
        {isAuthenticated && currentUser?.isadmin && (
          <Tab value="userList">注册用户</Tab>
        )}
        <Tab value="past">往期课程</Tab>
      </TabList>
      <div style={{ background: "#EFEFEF" }}>
        {tabSelectedValue === "available" && <AvailableCourses />}
        {tabSelectedValue === "my" && <MyCourses />}
        {tabSelectedValue === "userList" && <AllUsersList />}
        {tabSelectedValue === "past" && <PastCourses />}
      </div>
    </div>
  );
};

export default MainTabs;
