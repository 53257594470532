
import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    Caption2,
    Toaster
} from "@fluentui/react-components";

import { List, ListItem } from "@fluentui/react-list-preview";
import { websiteDataProvider } from '../dataSource/websiteDataProvider';
import { websitecourse } from '../dataSource/websitecourse';
import { AppContext, useAppContext } from '../dataSource/appContextProvider';
import CourseCard from './CourseCard';
import nothingImg from '../assets/nothing.jpg';
import { websitereservation } from '../dataSource/websitereservation';
import { websiteuser } from '../dataSource/websiteuser';

const useStyles = makeStyles({
    card: {
        margin: "auto",
        width: "500px",
        maxWidth: "100%",
    },
    listItem: {
        padding: "5px"
    },
    root: {
        // Stack the label above the field
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        gap: "2px"
    },
    input: {
        // Prevent the example from taking the full width of the page (optional)

    },
    field: {
        marginTop: '5px'
    }
});



const MyCourses = () => {
    const styles = useStyles();
    const dataProvider: websiteDataProvider = websiteDataProvider.getInstance();
    const [myCourses, setMyCourses] = useState<(websitecourse | undefined)[]>([]);
    const { isAuthenticated, currentUser, login } = useAppContext();
    const [reservationIds, setReservationIds] = useState<string[]>([]); // [1]
    let allCourses: websitecourse[] = [];
    const showMyCourses = async () => {
        allCourses = await dataProvider.getAllCourses();
        if (currentUser) {
            let resIds = [];
            const reservations = await dataProvider.getReservationsByUser(currentUser);
            resIds = reservations.map((reservation) => reservation.courseid);

            let myCourses = reservations.map((reservation: websitereservation) => {
                return allCourses.find((course: websitecourse) => course && course.id === reservation.courseid);
            });
            myCourses.sort((a, b) => {
                if (a?.startdate && b?.startdate) {
                    return new Date(b.startdate).getTime() - new Date(a.startdate).getTime();
                } else {
                    return 0;
                }
            });
            if (myCourses?.length > 0) {
                setReservationIds(resIds);
                setMyCourses(myCourses);
            }
        }
    }
    useEffect(() => {
        showMyCourses();
    }, [isAuthenticated]);
    return (
        myCourses == undefined || myCourses.length === 0 ? <div style={{ height: 300, width: 300 }}>
            <img height={300} width={300} src={nothingImg} alt="Description" />
        </div> :
            <List>
                {myCourses.map((course: websitecourse | undefined) => {
                    if (course) {
                        return (
                            <ListItem className={styles.listItem}>
                                <Toaster toasterId={'toaster'} />
                                <CourseCard course={course}
                                    currentUserReservationIds={reservationIds}
                                    refreshCourses={showMyCourses}
                                    allReservations={[]} allUsers={[]}
                                />
                            </ListItem>
                        );
                    }
                }
                )}
            </List >);
};

export default MyCourses;