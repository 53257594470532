import {
  Body1,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Field,
  makeStyles,
  SpinButton,
  SpinButtonChangeEvent,
  SpinButtonOnChangeData,
  Toast,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";
import React, { useState } from "react";
import { websiteuser } from "../dataSource/websiteuser";
import { websiteDataProvider } from "../dataSource/websiteDataProvider";

const useStyles = makeStyles({
  card: {
    margin: "auto",
    width: "500px",
    maxWidth: "100%",
  },
  listItem: {
    padding: "5px",
  },
  root: {
    // Stack the label above the field
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    gap: "2px",
  },
  input: {
    // Prevent the example from taking the full width of the page (optional)
    width: "50px",
  },
  field: {
    marginTop: "5px",
  },
  cardFooterRoot: {
    display: "flex",
    alignItems: "end",
  },
  applyButton: {
    marginLeft: "5px",
  },
});
export type userCardProps = {
  user: websiteuser;
};
const UserCard: React.FC<userCardProps> = (props: userCardProps) => {
  const [remaining, setRemaining] = useState<number>(props.user.remaining || 0);
  const dataProvider: websiteDataProvider = websiteDataProvider.getInstance();
  const { dispatchToast } = useToastController("toaster1");
  const styles = useStyles();
  return (
    <Card className={styles.card}>
      <CardHeader
        header={
          <Body1>
            <b>{props.user.studentname}</b>
          </Body1>
        }
        description={
          <div>
            <div>{"用户名: " + props.user.username}</div>
            <div>{"Email: " + props.user.email}</div>
            <div>{"电话号码: " + props.user.phonenumber}</div>
          </div>
        }
      />
      <CardFooter>
        <div className={styles.cardFooterRoot}>
          <Field className={styles.field} label="剩余课时">
            <SpinButton
              className={styles.input}
              type="text"
              value={remaining}
              min={0}
              max={100}
              onChange={(
                event: SpinButtonChangeEvent,
                data: SpinButtonOnChangeData
              ) => {
                if (data.value === 0 && remaining === 0) {
                  return;
                }
                if (data.value != null) {
                  setRemaining(data.value);
                }
              }}
              required
              id={"username"}
              {...props}
            />
          </Field>
          <Button
            appearance="primary"
            className={styles.applyButton}
            onClick={async () => {
              await dataProvider.updateUserAdmin(props.user, remaining);
              dataProvider.clearAllData();
              dispatchToast(
                <Toast>
                  <ToastTitle>充值成功！</ToastTitle>
                </Toast>,
                { position: "top", intent: "success" }
              );
            }}
          >
            充值
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
};

export default UserCard;
