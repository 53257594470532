import { get } from "http";
import { websitecourse } from "./websitecourse";
import { websitereservation } from "./websitereservation";
import { websiteuser } from "./websiteuser";

export class websiteDataProvider {
  static instance: websiteDataProvider;
  static getInstance(): websiteDataProvider {
    if (!websiteDataProvider.instance) {
      websiteDataProvider.instance = new websiteDataProvider();
    }
    return websiteDataProvider.instance;
  }
  private allCourses: websitecourse[] = [];
  private pastCourses: websitecourse[] = [];
  private currentCourses: websitecourse[] = [];
  private allUsers: websiteuser[] = [];
  private allReservations: websitereservation[] = [];

  clearAllData = () => {
    this.allCourses = [];
    this.allUsers = [];
    this.allReservations = [];
  };

  getAllUsers = async (): Promise<websiteuser[]> => {
    if (this.allUsers.length > 0) {
      return this.allUsers;
    }
    const response = await fetch("/data-api/rest/Users", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    const users: websiteuser[] = data.value;
    users.sort((a, b) => {
      if (a?.id && b?.id) {
        return Number.parseInt(b.id) - Number.parseInt(a.id);
      } else {
        return 0;
      }
    });
    this.allUsers = users;
    return users;
  };

  getUserByNameAndPassword = async (
    username: string,
    password: string
  ): Promise<websiteuser | undefined> => {
    const currentUser = await this.getAllUsers().then(
      (users: websiteuser[]) => {
        const user: websiteuser | undefined = users.find(
          (user) => user.username === username && user.password === password
        );
        return user;
      }
    );
    return currentUser;
  };

  getUserById = async (id: string): Promise<websiteuser> => {
    const response = await fetch(`/data-api/rest/Users/id/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data: websiteuser = await response.json();
    return data;
  };
  createUser = async (newUser: websiteuser): Promise<string> => {
    const allusers: websiteuser[] = await this.getAllUsers();

    const maxUserId: number = allusers.reduce(
      (maxId, obj) =>
        Number.parseInt(obj.id || "0") > maxId
          ? Number.parseInt(obj.id || "0")
          : maxId,
      -Infinity
    );
    const nextUserId: number = maxUserId + 1;
    const existingUser: websiteuser | undefined = allusers.find(
      (user) => user.username === newUser.username
    );
    if (existingUser) {
      return "User already exists, please use a different name.";
    }
    try {
      const response = await fetch("/data-api/rest/Users", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...newUser,
          id: nextUserId.toString(),
        }),
      });
      const data = response.json();
      return "";
    } catch (error) {
      return Promise.reject("Fail to create a new user.");
    }
  };

  updateUserAdmin = async (
    currentUser: websiteuser,
    remaining: number
  ): Promise<void> => {
    try {
      await fetch(`/data-api/rest/Users/id/${currentUser.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: currentUser.username,
          remaining: remaining,
          password: currentUser.password,
          email: currentUser.email,
          studentname: currentUser.studentname,
          phonenumber: currentUser.phonenumber,
          isadmin: currentUser.isadmin,
        }),
      });
    } catch (error) {
      return Promise.reject("Fail to update current.");
    }
  };

  updateUser = async (currentUser: websiteuser): Promise<void> => {
    try {
      if (currentUser.remaining) {
        await fetch(`/data-api/rest/Users/id/${currentUser.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: currentUser.username,
            remaining:
              currentUser.remaining > 1 ? currentUser.remaining - 1 : 0,
            password: currentUser.password,
            email: currentUser.email,
            studentname: currentUser.studentname,
            phonenumber: currentUser.phonenumber,
            isadmin: currentUser.isadmin,
          }),
        });
      }
    } catch (error) {
      return Promise.reject("Fail to update current.");
    }
  };
  getCurrentCourses = async (): Promise<websitecourse[]> => {
    if (this.currentCourses.length > 0) {
      return this.currentCourses;
    }
    const response = await fetch("/data-api/rest/Courses", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    const courses: websitecourse[] = data.value;
    if (courses.length > 0) {
      courses.sort((a, b) => {
        if (a?.startdate && b?.startdate) {
          return (
            new Date(a.startdate).getTime() - new Date(b.startdate).getTime()
          );
        } else {
          return 0;
        }
      });

      this.currentCourses = courses.filter((c) => c.state === 1);
    }
    return this.currentCourses;
  };

  getAllCourses = async (): Promise<websitecourse[]> => {
    if (this.allCourses.length > 0) {
      return this.allCourses;
    }
    const response = await fetch("/data-api/rest/Courses", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    const courses: websitecourse[] = data.value;
    if (courses.length > 0) {
      courses.sort((a, b) => {
        if (a?.startdate && b?.startdate) {
          return (
            new Date(a.startdate).getTime() - new Date(b.startdate).getTime()
          );
        } else {
          return 0;
        }
      });

      this.allCourses = courses;
    }
    return this.allCourses;
  };

  getPastCourses = async (): Promise<websitecourse[]> => {
    if (this.pastCourses.length > 0) {
      return this.pastCourses;
    }
    const response = await fetch("/data-api/rest/Courses", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    const courses: websitecourse[] = data.value;
    if (courses.length > 0) {
      courses.sort((a, b) => {
        if (a?.startdate && b?.startdate) {
          return (
            new Date(b.startdate).getTime() - new Date(a.startdate).getTime()
          );
        } else {
          return 0;
        }
      });
      this.pastCourses = courses.filter((c) => c.state === 0);
    }
    return this.pastCourses;
  };
  updateCourse = async (course: websitecourse): Promise<string> => {
    try {
      const id = course.id;
      const response = await fetch(`/data-api/rest/Courses/id/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reserved: course.reserved + 1,
          startdate: course.startdate,
          enddate: course.enddate,
          title: course.title,
          imageurl: course.imageurl,
          description: course.description,
          capacity: course.capacity,
          state: course.state,
        }),
      });
      return "";
    } catch (error) {
      return Promise.reject("Fail to update current.");
    }
  };

  reserveCourse = async (
    course: websitecourse,
    user: websiteuser
  ): Promise<void> => {
    const reservationId: number = new Date().valueOf();
    const newReservation: websitereservation = {
      id: reservationId.toString(),
      courseid: course.id || "",
      userid: user.id || "",
    };
    try {
      await fetch(`/data-api/rest/Reservations`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...newReservation,
        }),
      });
    } catch (error) {
      return Promise.reject("Fail to update current.");
    }
    // update course reserved
    await this.updateCourse(course);
    // update user remaining
    await this.updateUser(user);
    this.clearAllData();
  };

  getAllReservations = async (): Promise<websitereservation[]> => {
    if (this.allReservations.length > 0) {
      return this.allReservations; //
    }
    try {
      const response = await fetch("/data-api/rest/Reservations", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      const reservations: websitereservation[] = data.value;
      this.allReservations = reservations;
      return reservations;
    } catch (error) {
      return Promise.reject("Fail to update current.");
    }
  };
  getReservationsByUser = async (
    user: websiteuser
  ): Promise<websitereservation[]> => {
    const allReservations: websitereservation[] =
      await this.getAllReservations();
    const userReservations: websitereservation[] = allReservations.filter(
      (reservation) => reservation.userid === user.id
    );
    return userReservations;
  };

  getReservedStudentNames = (
    course: websitecourse,
    allReservations: websitereservation[],
    allUsers: websiteuser[]
  ): string[] => {
    const courseReservations: websitereservation[] = allReservations.filter(
      (reservation) => reservation.courseid === course.id
    );
    const reservedUserNames: string[] = courseReservations.map(
      (reservation) => {
        const user: websiteuser | undefined = allUsers.find(
          (user) => user.id === reservation.userid
        );
        return user?.studentname || "";
      }
    );
    return reservedUserNames;
  };
}
