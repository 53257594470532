
import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    Caption2,
    Toaster
} from "@fluentui/react-components";

import { List, ListItem } from "@fluentui/react-list-preview";
import { websiteDataProvider } from '../dataSource/websiteDataProvider';
import { websitecourse } from '../dataSource/websitecourse';
import { AppContext, useAppContext } from '../dataSource/appContextProvider';
import CourseCard from './CourseCard';
import nothingImg from '../assets/nothing.jpg';
import { websiteuser } from '../dataSource/websiteuser';
import { websitereservation } from '../dataSource/websitereservation';

const useStyles = makeStyles({
    card: {
        margin: "auto",
        width: "500px",
        maxWidth: "100%",
    },
    listItem: {
        padding: "5px"
    },
    root: {
        // Stack the label above the field
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        gap: "2px"
    },
    input: {
        // Prevent the example from taking the full width of the page (optional)

    },
    field: {
        marginTop: '5px'
    }
});



const AvailableCourses = () => {
    const styles = useStyles();
    const dataProvider: websiteDataProvider = websiteDataProvider.getInstance();
    const [availableCourses, setAvailableCourses] = useState<websitecourse[]>([]);
    const [reservationIds, setReservationIds] = useState<string[]>([]); // [1]
    const { isAuthenticated, currentUser, login } = useAppContext();
    let allCourses: websitecourse[] = [];

    const [allUsers, setAllUsers] = useState<websiteuser[]>([]);
    const [allReservations, setAllReservations] = useState<websitereservation[]>([]);

    const showCourses = async () => {
        allCourses = await dataProvider.getCurrentCourses();
        let resIds: string[] = [];
        if (isAuthenticated && currentUser) {
            const reservations = await dataProvider.getReservationsByUser(currentUser);
            resIds = reservations.map((reservation) => reservation.courseid);
        }
        setReservationIds(resIds);
        setAvailableCourses(allCourses);
        if (currentUser?.isadmin) {
            setAllUsers(await dataProvider.getAllUsers());
            setAllReservations(await dataProvider.getAllReservations());
        }
    }


    useEffect(() => {
        showCourses();
    }, [isAuthenticated]);
    return (
        availableCourses == undefined || availableCourses.length === 0 ?
            <div style={{ height: 300, width: 300 }}>
                <img height={300} width={300} src={nothingImg} alt="Description" />
            </div> :
            <List>
                {availableCourses.map((course: websitecourse) => {
                    return (
                        <ListItem className={styles.listItem}>
                            <Toaster toasterId={'toaster'} />
                            <CourseCard course={course} currentUserReservationIds={reservationIds} refreshCourses={() => {
                                showCourses();
                            }}
                                allReservations={allReservations}
                                allUsers={allUsers}
                            />
                        </ListItem>
                    );
                }
                )}
            </List >);
};

export default AvailableCourses;