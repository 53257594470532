
import './App.css';
import {
  FluentProvider,
  webLightTheme,
  Divider,
} from "@fluentui/react-components";
import { Topbar } from './topbar';
import { AppContextProvider } from './dataSource/appContextProvider';
import MainTabs from './mainTabs';

export default function App() {

  return (
    <FluentProvider theme={webLightTheme}>
      <AppContextProvider>
        <div>
          <Topbar />
          <Divider />
          <MainTabs />
        </div>
      </AppContextProvider>
    </FluentProvider>
  );
}

