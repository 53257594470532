
import React from 'react';
import {
    makeStyles,
    Body1,
    Caption1,
    Button,
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    DialogActions,
    useToastController,
    Toast,
    ToastTitle,
    Label,
} from "@fluentui/react-components";
import { CalendarAddRegular } from "@fluentui/react-icons";
import {
    Card,
    CardFooter,
    CardHeader,
    CardPreview,
} from "@fluentui/react-components";
import { websitecourse } from '../dataSource/websitecourse';
import { formatEndDate, formatStartDate } from '../textUtilities';
import { useAppContext } from '../dataSource/appContextProvider';
import { websiteDataProvider } from '../dataSource/websiteDataProvider';
import { PresenceBadge } from "@fluentui/react-components";
import { websiteuser } from '../dataSource/websiteuser';
import { websitereservation } from '../dataSource/websitereservation';

const useStyles = makeStyles({
    card: {
        margin: "auto",
        width: "500px",
        maxWidth: "100%",
    },
    listItem: {
        padding: "5px"
    },
    root: {
        // Stack the label above the field
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        gap: "2px"
    },
    input: {
        // Prevent the example from taking the full width of the page (optional)

    },
    field: {
        marginTop: '5px'
    }
});

export type courseCardProps = {
    course: websitecourse;
    currentUserReservationIds: string[];
    allUsers: websiteuser[];
    allReservations: websitereservation[];
    refreshCourses: () => void;
}

const CourseCard: React.FC<courseCardProps> = (props: courseCardProps) => {
    const { course, currentUserReservationIds, refreshCourses, allReservations, allUsers } = props;
    const dataProvider: websiteDataProvider = websiteDataProvider.getInstance();
    const styles = useStyles();
    const { isAuthenticated, currentUser, login } = useAppContext();
    const { dispatchToast } = useToastController('toaster');
    const handleReserve = async (selectedCourse: websitecourse) => {
        if (currentUser !== null && selectedCourse !== null) {
            await dataProvider.reserveCourse(selectedCourse, currentUser);
            const reloginUser = await dataProvider.getUserByNameAndPassword(currentUser.username, currentUser.password);
            if (reloginUser) {
                login(reloginUser);
                notify();
                refreshCourses();
            }
        }
    }
    const notify = () =>
        dispatchToast(
            <Toast>
                <ToastTitle>课程预约成功！</ToastTitle>
            </Toast>,
            { position: 'top', intent: "success" }
        );
    const isReservable = (course: websitecourse) => {
        if (!isAuthenticated) {
            return false;
        }
        if (currentUser?.remaining == undefined || currentUser?.remaining == 0) {
            return false;
        }
        const now = new Date();
        const startDate = new Date(course.startdate);
        if (startDate < now) {
            return false;
        }
        if (course.capacity <= course.reserved) {
            return false;
        }
        return true;

    }
    const isFinished = (course: websitecourse) => {
        const now = new Date();
        const endDate = new Date(course.enddate);
        if (endDate < now) {
            return true;
        }
        return false;
    }

    const isReserved = (course: websitecourse) => {
        if (currentUserReservationIds.includes(course.id || '')) {
            return true;
        }
        return false;
    }

    return (<Card className={styles.card}>
        <CardHeader
            header={
                <Body1>
                    <b>{course.title}</b>
                </Body1>
            }
            description={<Caption1> <div>
                <br />
                <b>{formatStartDate(course.startdate)}-{formatEndDate(course.enddate)}</b>
                <br />
                {course.description}
                <br />
            </div>
            </Caption1>}
        />
        <CardPreview>
            <img
                src={course.imageurl}
                alt="Preview of a Word document: About Us - Overview"
            />
        </CardPreview>
        <CardFooter>
            {!isReserved(course) && !isFinished(course) && <Dialog modalType='modal'>
                <DialogTrigger disableButtonEnhancement>
                    {<Button disabled={!isReservable(course)} appearance='primary' icon={<CalendarAddRegular fontSize={16} />}>预约</Button>}
                </DialogTrigger>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>预约课程</DialogTitle>
                        <DialogContent>
                            <div >
                                {'确定预约课程 - ' + course.title + formatStartDate(course.startdate) + '吗?  预约成功后如需取消请联系我哦。'}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance='primary' onClick={() => handleReserve(course)}>确认</Button>
                            </DialogTrigger>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary">取消</Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>}
            {isReserved(course) && !isFinished(course) && <div style={{ alignItems: 'center', display: 'flex' }}>
                <PresenceBadge status="available" />
                <Label style={{ marginLeft: 5 }}>已预约</Label>
            </div>}
            {isFinished(course) && <div style={{ alignItems: 'center', display: 'flex' }}>
                <PresenceBadge status="unknown" />
                <Label style={{ marginLeft: 5 }}>已结束</Label>
            </div>}
            <div style={{ alignItems: 'center', display: 'flex' }}>
                <Label style={{ marginLeft: 5 }}>{`人数(${course.reserved}/${course.capacity})`}</Label>
            </div>
            {currentUser?.isadmin && <div style={{ alignItems: 'center', display: 'flex' }}>
                <Label style={{ marginLeft: 5 }}>{dataProvider.getReservedStudentNames(course, allReservations, allUsers).join(';')}</Label>
            </div>}
        </CardFooter>
    </Card>);
}

export default CourseCard;