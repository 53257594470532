import React from 'react';
import { Toolbar, ToolbarButton, ToolbarDivider, Avatar, Text } from '@fluentui/react-components';
import RegisterForm from './userManagement/register';
import { useAppContext } from './dataSource/appContextProvider';
import LoginForm from './userManagement/login';

export const Topbar: React.FC = () => {
    const { isAuthenticated, currentUser, logout } = useAppContext();

    const handleLogout = () => {
        logout();
    };
    return (
        <Toolbar style={{ margin: 5 }}>
            {isAuthenticated && currentUser ? (
                <>
                    <Avatar image={{ src: '' }} style={{ marginRight: 10 }} />
                    <Text>欢迎, {currentUser.studentname}</Text>
                    <ToolbarDivider />
                    <Text>剩余次数:{currentUser.remaining || 0}</Text>
                    <ToolbarDivider />
                    <ToolbarButton onClick={handleLogout}>
                        退出
                    </ToolbarButton>
                </>
            ) : (
                <>
                    <LoginForm />
                    <ToolbarDivider />
                    <RegisterForm />
                </>
            )}
        </Toolbar>
    );
}