export const formatStartDate = (datetimeString: string) => {
    const date = new Date(datetimeString);
    return new Intl.DateTimeFormat('zh-CN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    }).format(date);
};

export const formatEndDate = (datetimeString: string) => {
    const date = new Date(datetimeString);
    return new Intl.DateTimeFormat('zh-CN', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    }).format(date);
};